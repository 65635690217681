import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import team from '../assets/images/team/team2025.jpeg'
import KW_Zoom from '../assets/images/sponsor/KW_Zoom.jpg'
import build from '../assets/images/other/build.png'
import cadPIC from '../assets/images/other/Battery_Mount.png'
import codePIC from '../assets/images/other/code.jpg'
import Outreach_Pic from '../assets/images/other/rotary.jpg'
import Motivate from '../assets/images/connect/connect.jpeg'
import Robot from '../assets/images/other/robotCover.png'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Krypton Warriors"
          meta={[
            { name: 'description', content: 'KW' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        ></Helmet>
        <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "53ca0e93ef80463192e735fa104fef39"}'></script>

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${team})` }}>
              <header className="major">
                <h3>Team</h3>
                <p>Who We Are</p>
              </header>
              <Link to="/team" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${Motivate})` }}>
              <header className="major">
                <h3>Connect</h3>
                <p>Engaging with STEM Professionals</p>
              </header>
              <Link to="/connect" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${Outreach_Pic})` }}>
              <header className="major">
                <h3>Motivate</h3>
                <p>Inspiring the Community</p>
              </header>
              <a href="motivate" className="link primary"></a>
            </article>
            <article style={{ backgroundImage: `url(${Robot})` }}>
              <header className="major">
                <h3>Robot</h3>
                <p>Our Robot</p>
              </header>
              <a href="robot" className="link primary"></a>
            </article>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
